.publication {
  position: relative;
  background: var(--grey-color);
  padding: 20px 40px;
  transition: background $duration-normal ease-out;
  margin-bottom: 24px;

  &:hover {
    background: $clrGrey;

    .wysiwyg {
      p {
        // color: $clrWhite;
      }
    }
  }

  .wysiwyg {
    p {
      margin-bottom: 24px;
      transition: color $duration-normal ease-out;
    }
  }

  &__abstract-btn {
    display: flex;
    border: none;
    background-color: $clrPrimary;
    align-items: center;
    padding: 2px 10px;
    color: $clrWhite;
    font-size: 14px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 20;

    svg {
      width: 15px;
      fill: $clrWhite;
      margin-left: 10px;
    }
  }
}