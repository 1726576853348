.image-dowloads__item {
  position: relative;

  &__link {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
    background-color: $clrPrimary;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-out;

    &:hover {
      background-color: $clrPrimaryDarker;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: $clrWhite;
    }
  }
}
