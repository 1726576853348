.wysiwyg {
  &--introduction {
    p {
      font-size: $fontSize20;
    }
  }

  // typography -----------------------------------------------------------------------------------------
  h2, h3, h4, h5, h6 {
    color: $clrInk;
    margin-top: $paragraphSpacings;

    &:only-child {
      margin-top: 0;
    }
  }

  p {
    margin-top: $paragraphSpacings;
  }

  a {
    text-decoration: underline;
    color: $clrPrimary;
    transition: color 0.2s linear;

    &:hover {
      color: $clrPrimaryDarker;
    }
  }

  // lists ----------------------------------------------------------------------------------------------
  ul {
    margin-top: $paragraphSpacings;
    list-style: none;

    li {
      padding-bottom: 20px;
      padding-left: 32px;
      position: relative;
      display: block;

      &:last-child {
        padding-bottom: 0;
      }

      &::before {
        content: url("data:image/svg+xml; utf8, %3Csvg id='Check' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_584' data-name='Rectangle 584' width='24' height='24' fill='none'/%3E%3Cpath id='Path_686' data-name='Path 686' d='M11.136,22.893a1.025,1.025,0,0,1-.727-.3L3.551,15.734A1.029,1.029,0,1,1,5.006,14.28l6.13,6.13L25.494,6.051a1.029,1.029,0,0,1,1.455,1.455L11.863,22.592A1.025,1.025,0,0,1,11.136,22.893Z' transform='translate(-3.25 -2.322)' fill='%231e9444'/%3E%3C/svg%3E%0A");
        display: block;
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px;
      }
    }
  }

  ol {
    margin-top: $paragraphSpacings;
    padding: 0;
    list-style: none;
    counter-reset: a;

    li {
      padding-bottom: 20px;
      padding-left: 20px;
      position: relative;
      display: block;
      counter-increment: a;

      &:last-child {
        padding-bottom: 0;
      }

      &::before {
        content: counter(a)  ". ";
        display: block;
        position: absolute;
        left: 0;
      }
    }
  }

  // various --------------------------------------------------------------------------------------------
  blockquote {
    text-align: center;
    display: flex;
    justify-content: center;

    &::before,
    &::after {
      content: open-quote;
      margin-right: 15px;
      display: inline-block;
      font-size: 60px;
      color: #e0e0e0;
      line-height: 1;
    }

    &::after {
      content: close-quote;
      margin-left: 15px;
      margin-right: 0;
    }
  }

  table {
    width: 100%;
    margin-top: $paragraphSpacings;
    border: 1px solid $clrGreyDark;

    td {
      border: 1px solid $clrGreyDark;
      padding: 20px 25px;
    }
  }

  pre {
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    background: rgba(0, 0, 0, 0.02);
    font-family: "Courier New", Courier, monospace;
    color: inherit;
  }

  .button,
  button {
    margin: 0 10px 15px 0;
  }
}
