:root {
  --primary-color: #0085bd;
  --primary-dark-color: #004866;
}

#pq-loading img {
  height: 120px;
}

header#pq-header.pq-header-style-2.pq-header-sticky {
  overflow-x: visible;
}

header#pq-header.pq-header-style-2 .pq-header-diff-block .navbar-brand {
  background: #fff;
}

header#pq-header.pq-header-style-2 .pq-header-diff-block .navbar-brand img {
  object-fit: contain;
}

@media (min-width: 992px) {
  header#pq-header.pq-header-style-2 .pq-header-diff-block .navbar-brand img {
    height: 90px;
  }
}

.pq-slider-logo-img {
  width: 140px;
}

footer#pq-footer .pq-footer-logo {
  height: 150px;
}

.pq-breadcrumb {
  padding: 100px 0;
}

.pq-breadcrumb.home {
  padding: 160px 0;
}

.pq-breadcrumb.page .pq-button {
  margin-top: 20px;
}

.pq-breadcrumb__img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.pq-breadcrumb__img img {
  height: 100%;
  object-fit: cover;
}

.pq-breadcrumb__overlay {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #fff 0%, #ffffffbc 19%, #ffffff8a 34%, #ffffff61 47%, #ffffff47 56.5%, #ffffff31 65%, #ffffff20 73%, #ffffff13 80.2%, #ffffff0b 86.1%, #ffffff05 91%, #ffffff02 95.2%, #ffffff01 98.2%, #fff0 100%);
  position: absolute;
  top: 0;
  left: 0;
}

.pq-button {
  cursor: pointer;
  pointer-events: auto;
}

.section-component--white {
  padding-top: 0;
}

.section-component--mollie-form {
  background-color: #f3f3fe;
}

.pq-bg-grey + .section-component--white {
  margin-top: 90px;
}

@media (min-width: 992px) {
  .pq-bg-grey + .section-component--white {
    margin-top: 130px;
  }
}

.section-component--mollie-form + .section-component--white {
  padding-top: 90px;
}

@media (min-width: 992px) {
  .section-component--mollie-form + .section-component--white {
    padding-top: 130px !important;
  }
}

.hero + .section-component--white {
  padding-top: 90px !important;
}

@media (min-width: 992px) {
  .hero + .section-component--white {
    padding-top: 130px !important;
  }
}

.pq-form-box, .pq-applyform input {
  margin-bottom: 0;
}

.pq-applyform input::placeholder {
  color: #0000004d;
}

.pq-applyform textarea {
  margin-bottom: 0;
}

.pq-applyform textarea::placeholder {
  color: #0000004d;
}

.pq-applyform .errors {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
}

.pq-applyform .errors li {
  color: #f86d6d;
  font-size: .875rem;
}

.pq-blog-post {
  height: 100%;
}

button.g-recaptcha {
  background: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  font-family: var(--title-fonts);
  text-transform: uppercase;
  letter-spacing: .04em;
  color: var(--white-color);
  width: auto;
  height: auto;
  -o-transition: all .5s ease-in-out;
  border: none;
  padding: 12px 30px;
  line-height: 2;
  transition: all .5s ease-in-out;
  display: inline-block;
}

button.g-recaptcha:hover {
  background: var(--dark-color);
}

.partners .pq-fancy-box {
  position: relative;
}

.partners .pq-fancy-box .pq-fancy-box-title {
  font-size: 24px;
  line-height: 1.2;
}

.partners .pq-fancy-box-icon {
  max-height: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.partners .pq-fancy-box-icon picture {
  width: 100%;
  height: 100%;
}

.partners .pq-fancy-box-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-dowloads__item {
  position: relative;
}

.image-dowloads__item__link {
  width: 40px;
  height: 40px;
  background-color: var(--primary-color);
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  transition: background-color .3s ease-out;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.image-dowloads__item__link:hover {
  background-color: var(--primary-dark-color);
}

.image-dowloads__item__link svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.wysiwyg--introduction p {
  font-size: 1.25rem;
}

.wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
  color: #1d1d1d;
  margin-top: 24px;
}

.wysiwyg h2:only-child, .wysiwyg h3:only-child, .wysiwyg h4:only-child, .wysiwyg h5:only-child, .wysiwyg h6:only-child {
  margin-top: 0;
}

.wysiwyg p {
  margin-top: 24px;
}

.wysiwyg a {
  color: var(--primary-color);
  text-decoration: underline;
  transition: color .2s linear;
}

.wysiwyg a:hover {
  color: var(--primary-dark-color);
}

.wysiwyg ul {
  margin-top: 24px;
  list-style: none;
}

.wysiwyg ul li {
  padding-bottom: 20px;
  padding-left: 32px;
  display: block;
  position: relative;
}

.wysiwyg ul li:last-child {
  padding-bottom: 0;
}

.wysiwyg ul li:before {
  content: url("data:image/svg+xml; utf8, %3Csvg id='Check' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Crect id='Rectangle_584' data-name='Rectangle 584' width='24' height='24' fill='none'/%3E%3Cpath id='Path_686' data-name='Path 686' d='M11.136,22.893a1.025,1.025,0,0,1-.727-.3L3.551,15.734A1.029,1.029,0,1,1,5.006,14.28l6.13,6.13L25.494,6.051a1.029,1.029,0,0,1,1.455,1.455L11.863,22.592A1.025,1.025,0,0,1,11.136,22.893Z' transform='translate(-3.25 -2.322)' fill='%231e9444'/%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  left: 0;
}

.wysiwyg ol {
  counter-reset: a;
  margin-top: 24px;
  padding: 0;
  list-style: none;
}

.wysiwyg ol li {
  counter-increment: a;
  padding-bottom: 20px;
  padding-left: 20px;
  display: block;
  position: relative;
}

.wysiwyg ol li:last-child {
  padding-bottom: 0;
}

.wysiwyg ol li:before {
  content: counter(a) ". ";
  display: block;
  position: absolute;
  left: 0;
}

.wysiwyg blockquote {
  text-align: center;
  justify-content: center;
  display: flex;
}

.wysiwyg blockquote:before, .wysiwyg blockquote:after {
  content: open-quote;
  color: #e0e0e0;
  margin-right: 15px;
  font-size: 60px;
  line-height: 1;
  display: inline-block;
}

.wysiwyg blockquote:after {
  content: close-quote;
  margin-left: 15px;
  margin-right: 0;
}

.wysiwyg table {
  width: 100%;
  border: 1px solid #707070;
  margin-top: 24px;
}

.wysiwyg table td {
  border: 1px solid #707070;
  padding: 20px 25px;
}

.wysiwyg pre {
  color: inherit;
  background: #00000005;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px;
  font-family: Courier New, Courier, monospace;
}

.wysiwyg .button, .wysiwyg button {
  margin: 0 10px 15px 0;
}

.publication {
  background: var(--grey-color);
  margin-bottom: 24px;
  padding: 20px 40px;
  transition: background .3s ease-out;
  position: relative;
}

.publication:hover {
  background: #ddd;
}

.publication .wysiwyg p {
  margin-bottom: 24px;
  transition: color .3s ease-out;
}

.publication__abstract-btn {
  background-color: var(--primary-color);
  color: #fff;
  z-index: 20;
  border: none;
  align-items: center;
  padding: 2px 10px;
  font-size: 14px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.publication__abstract-btn svg {
  width: 15px;
  fill: #fff;
  margin-left: 10px;
}

.cookie__banner {
  z-index: 1054;
  animation: 1s cubic-bezier(.075, .82, .165, 1) fadeEffect;
  display: none;
}

.cookie__banner.js-visible {
  display: block;
}

.cookie__banner .hidden {
  display: none;
}

.cookie__banner--fullscreen {
  width: 100vw;
  height: 100vh;
  background: #00000080;
  padding-top: 150px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.cookie__banner--fullscreen .cookie__banner__inner {
  width: calc(100% - 40px);
  max-height: calc(100vh - 40px);
  margin-bottom: 10%;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.cookie__banner__inner {
  z-index: 50;
  width: 100%;
  color: #444;
  -ms-overflow-style: none;
  max-height: 100vh;
  background-color: #f0f0f0;
  padding: 30px 30px 30px 20px;
  font-size: .75rem;
  line-height: 20px;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  box-shadow: 2px 3px 21px -6px #000000bf;
}

@media screen and (min-width: 767px) {
  .cookie__banner__inner {
    max-width: 500px;
    min-width: 500px;
    bottom: 20px;
    left: 20px;
  }
}

.cookie__banner__inner .cookie-animation_rect {
  width: 100%;
  height: 30px;
  z-index: -1;
  background-color: #f0f0f0;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.cookie__message {
  animation: 1s cubic-bezier(.075, .82, .165, 1) fadeEffect;
  display: none;
}

.cookie__message.js-visible {
  display: block;
}

.cookie__message p {
  margin-bottom: .5rem;
  line-height: 20px;
}

.cookie__policy-link {
  color: inherit;
  background-color: #0000;
  border: none;
  margin-bottom: .5rem;
  text-decoration: underline;
}

.cookie__details__wrapper {
  animation: 1s cubic-bezier(.075, .82, .165, 1) fadeEffect;
  display: none;
}

.cookie__details__wrapper.js-visible, .cookie__details.js-visible {
  display: block;
}

.cookie__details__content {
  padding-top: 60px;
  display: none;
}

.cookie__details__content.js-visible {
  display: block;
}

.cookie__details__back-btn {
  background: #fff;
  border: none;
  align-items: center;
  padding: 5px 10px;
  transition: all .4s ease-out;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.cookie__details__back-btn:hover {
  background-color: #ddd;
}

.cookie__details__back-btn svg {
  stroke: #000;
  fill: none;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.cookie__details .host {
  margin: 10px 0;
}

.cookie__details .host.js-visible .host__title svg {
  transform: rotate(180deg);
}

.cookie__details .host.js-visible .host__cookie__wrapper {
  display: block;
}

.cookie__details .host__title {
  color: #000;
  cursor: pointer;
  background-color: #ddd;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  display: flex;
}

.cookie__details .host__title svg {
  stroke: #000;
  fill: none;
  width: 10px;
  height: 6px;
  transition: all .4s ease-out;
  display: block;
}

.cookie__details .host__cookie {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
}

.cookie__details .host__cookie__wrapper {
  animation: 1s cubic-bezier(.075, .82, .165, 1) fadeEffect;
  display: none;
}

.cookie__details .host__cookie__wrapper.js-visible {
  display: block;
}

.cookie__details .host__cookie__field {
  display: flex;
}

.cookie__details .host__cookie__label {
  min-width: 100px;
  font-weight: bold;
}

.cookie__options {
  margin-top: -10px;
  animation: 1s cubic-bezier(.075, .82, .165, 1) fadeEffect;
  display: none;
  position: relative;
}

.cookie__options.js-visible {
  display: block;
}

.cookie__options__checkbox-wrapper {
  animation: 1s cubic-bezier(.075, .82, .165, 1) fadeEffect;
  display: none;
}

.cookie__options__checkbox-wrapper.js-visible {
  display: block;
}

.cookie__options .cookie__options__title {
  width: calc(100% - 80px);
  padding-bottom: 20px;
  display: block;
}

.cookie__options .cookie__button-close {
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -7px;
  right: -15px;
}

.cookie__options .cookie__button-close i {
  width: 30px;
  height: 1px;
  transform-origin: center;
  background: #000;
  display: block;
  position: absolute;
  top: 50%;
  left: calc(50% - 15px);
  transform: rotate(45deg);
}

.cookie__options .cookie__button-close i:last-child {
  transform: rotate(-45deg);
}

.cookie__options form {
  margin-top: 25px;
  margin-bottom: 40px;
}

.cookie__checkbox {
  align-items: center;
  margin-bottom: 15px;
  display: flex;
  position: relative;
}

.cookie__checkbox.disabled {
  opacity: .5;
}

.cookie__checkbox__info__title {
  cursor: pointer;
  align-items: center;
  font-weight: bold;
  display: flex;
}

.cookie__checkbox__info__title:after {
  content: "+";
  margin-left: .5rem;
  font-size: 18px;
  display: block;
}

.cookie__checkbox__info__btn-details {
  color: #000;
  background-color: #0000;
  border: none;
  margin-top: 10px;
  padding: 0;
  font-size: .75rem;
  text-decoration: underline;
}

.cookie__checkbox__info__descr {
  animation: 1s cubic-bezier(.075, .82, .165, 1) fadeEffectSmall;
  display: none;
}

.cookie__checkbox__info.js-visible .cookie__checkbox__info__title:after {
  content: "-";
}

.cookie__checkbox__info.js-visible .cookie__checkbox__info__descr {
  display: block;
}

.cookie__checkbox .switch {
  width: 30px;
  height: 17px;
  flex-shrink: 0;
  margin-bottom: 0;
  margin-right: 1rem;
  display: inline-block;
  position: relative;
}

.cookie__checkbox .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cookie__checkbox .switch input:checked + .slider {
  background-color: var(--primary-color);
}

.cookie__checkbox .switch input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.cookie__checkbox .switch input:checked + .slider:before {
  transform: translateX(13px);
}

.cookie__checkbox .switch .slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.cookie__checkbox .switch .slider:before {
  content: "";
  height: 13px;
  width: 13px;
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
}

.cookie__checkbox .switch .slider.round {
  border-radius: 34px;
}

.cookie__checkbox .switch .slider.round:before {
  border-radius: 50%;
}

.cookie__button-wrapper {
  margin-top: 20px;
}

.cookie__button-wrapper.hidden {
  display: none;
}

.cookie__button-wrapper .button {
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-right: 15px;
  font-size: 12px;
  font-weight: bold;
  transition: all .3s ease-out;
  display: inline-block;
}

.cookie__button-wrapper .button:hover {
  text-decoration: none;
}

.cookie__button-wrapper .button--primary {
  background: var(--primary-color);
  color: #fff;
  padding: 10px 20px;
}

.cookie__button-wrapper .button--primary:hover {
  background: var(--primary-dark-color);
  transform: scale(1.1);
}

.cookie__button, .cookie__button--faded {
  background-color: #444;
  padding: 10px 25px;
  transition: background .4s linear;
  margin: 0 10px 10px 0 !important;
}

.cookie__button:hover, .cookie__button--faded:hover {
  color: #000;
  background-color: #777;
  text-decoration: none;
}

.app-body .cookie__button {
  color: #fff;
}

.app-body .cookie__button--faded {
  color: #444;
}

.cookie__button--faded {
  background-color: #fff;
}

.cookie__button--faded:hover {
  background-color: #ddd;
}

@keyframes fadeEffect {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeEffectSmall {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*# sourceMappingURL=app.css.map */
