// Override the softboilerplate's defaults here

// colors
$clrWhite: #fff;
$clrBlack: #000;

$clrPrimary: var(--primary-color) !default;
$clrPrimaryDarker: var(--primary-dark-color) !default;
$clrBlue: #4085c6 !default;
$clrGreyBorder: #cccccc !default;
$clrGreyBorderDark: #c1c9c0 !default;
$clrGrey: #ddd !default;
$clrGreyLight: #f7f7f7 !default;
$clrGreyMedium: #eaeaea;
$clrGreyForm: #f3f3fe !default;
$clrGreyDark: #707070 !default;
$clrInk: #1d1d1d !default;
$clrError: #f86d6d;
$clrSuccess: #06b232;

$clrBodyFont: #212529;
$clrHeaderFont: $clrBodyFont;
$clrLinkFont: $clrBlue;

// sizes & measuring
$bodyMaxWidth: 1600px;
$componentSpacings: (small: 42px, medium: 72px, large: 80px, xlarge: 160px);
$componentSpacingsMobile : (small: 24px, medium: 42px, large: 60px, xlarge: 80px);
$gutterSpacing: 24px;
$paragraphSpacings: 24px;

// spacings
$paragraphMarginBottom: 50px;

// fonts & font sizes
$bodyFontFamily: 'Roboto', sans-serif;
$headerFontFamily: 'Roboto', sans-serif;
$fontSize12: 0.75rem; // a practical system these to convert the pixel values in the design to rem. this way, referencing to the values in the design is easier. And changing the font-size site-wide is easier ('the font looks so big. can we make it smaller?')
$fontSize13: 0.8125rem;
$fontSize14: 0.875rem;
$fontSize16: 1rem;
$fontSize18: 1.125rem;
$fontSize20: 1.25rem;
$fontSize22: 1.375rem;
$fontSize24: 1.5rem;
$fontSize28: 1.75rem;
$fontSize32: 2rem;
$fontSize36: 2.25rem;
$fontSize40: 2.5rem;
$fontSize48: 3rem;
$fontSize56: 3.5rem;
//
$bodyFontSize: $fontSize16;
$h1FontSize: $fontSize48;
$h2FontSize: $fontSize36;
$tinyFontSize: $fontSize12;
$bodyLineHeight: 1.5;
$headerLineHeight: 1.5;

// animation
$transition-duration: 0.4s;
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// z-indexes
$zBottom: 0;
$zMiddle: 1;
$zTop: 2;

$zMenuTop: 11;
$zMenuMiddle: 10;
$zMenuBottom: 9;

// Geysir
$dialogBtnBg: #504d61;
$clrTableDrag: #0a6fb4;
$clrBgParagraphWrapper: rgba(85, 142, 213, 0.1);

$duration-fast: 100ms; // Use for fast hover effects
$duration-normal: 300ms;
$duration-slow: 500ms; // Use for big transitions (accordion, page switch)
$duration-user-flow: 5000ms; // Below this point feels like a coherent application flow
$duration-attention-span: 12000ms; // After that point, the user loses his/her attention
