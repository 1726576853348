:root {
  --primary-color: #0085bd;
  --primary-dark-color: #004866;
}

// Loading logo
#pq-loading {
  img {
    height: 120px;
  }
}

.rs-parallax-wrap {

}

// Header Logo
header#pq-header.pq-header-style-2.pq-header-sticky {
  overflow-x: visible;
}

header#pq-header.pq-header-style-2 .pq-header-diff-block .navbar-brand {
  background: $clrWhite;

  img {
    object-fit: contain;

    @include media-breakpoint-up(lg) {
      height: 90px;
    }
  }
}

// Sidebar slidin
.pq-slider-logo-img {
  width: 140px;
}

// Footer Logo
footer#pq-footer .pq-footer-logo {
  height: 150px;
}


// Hero Banner
.pq-breadcrumb {
  padding: 100px 0;

  &.home {
    padding: 160px 0;
  }

  &.page {
    .pq-button {
      margin-top: 20px;
    }
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay {
    @include scrimGradient($clrWhite, 'to right');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// buttons
.pq-button {
  cursor: pointer;
  pointer-events: auto;
}

.section-component {
  /*
  &.pq-bg-grey {
    margin-bottom: 90px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 130px;
    }
  }

   */

  &--white {
    padding-top: 0;
  }

  &--mollie-form {
    background-color: $clrGreyForm;
  }
}

.pq-bg-grey + .section-component--white {
  margin-top: 90px;

  @include media-breakpoint-up(lg) {
    margin-top: 130px;
  }
}

.section-component--mollie-form + .section-component--white {
  padding-top: 90px;

  @include media-breakpoint-up(lg) {
    padding-top: 130px !important;
  }
}

// Hack to add padding top to the first section when it's white
.hero + .section-component--white {
  padding-top: 90px !important;

  @include media-breakpoint-up(lg) {
    padding-top: 130px !important;
  }
}

// Forms
.pq-form-box {
  margin-bottom: 0;
}

.pq-applyform {
  input {
    margin-bottom: 0;

    &::placeholder {
      color: rgba($clrBlack, 0.3);
    }
  }

  textarea {
    margin-bottom: 0;

    &::placeholder {
      color: rgba($clrBlack, 0.3);
    }
  }

  .errors {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;

    li {
      color: $clrError;
      font-size: $fontSize14;
    }
  }
}

.pq-blog-post {
  height: 100%;
}

button.g-recaptcha {
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 600;
  background: var(--primary-color);
  font-family: var(--title-fonts);
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: var(--white-color);
  display: inline-block;
  border: none;
  width: auto;
  height: auto;
  line-height: 2;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

button.g-recaptcha:hover {
  background: var(--dark-color);
}

// Partners block
.partners {
  .pq-fancy-box {
    position: relative;

    .pq-fancy-box-title {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  .pq-fancy-box-icon {
    max-height: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    picture {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
